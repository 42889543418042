import {
  GET_CLASS_SCHEDULES,
  GET_CLASS_SCHEDULES_SUCCESS,
  GET_CLASS_SCHEDULES_ERROR,
  ADD_CLASS_SCHEDULES,
  ADD_CLASS_SCHEDULES_SUCCESS,
  ADD_CLASS_SCHEDULES_ERROR,
  DELETE_CLASS_SCHEDULES,
  DELETE_CLASS_SCHEDULES_SUCCESS,
  DELETE_CLASS_SCHEDULES_ERROR,
  FILTER_CLASS_SCHEDULES,
  FILTER_CLASS_SCHEDULES_SUCCESS,
  FILTER_CLASS_SCHEDULES_ERROR,
  GET_TIME_PAIRS,
  GET_TIME_PAIRS_SUCCESS,
  GET_TIME_PAIRS_ERROR,
} from '../constants';

const INIT_STATE = {
  schedules: [],
  meta: null,
  loading: false,
  error: null,
  addError: null,
  deleteError: null,
  filterError: null,
  timePairs: [],
  timePairsLoading: false,
  timePairsError: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASS_SCHEDULES:
      return { ...state, loading: true, error: null };
    case GET_CLASS_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,
        schedules: action.payload.schedules,
        meta: action.payload.meta
      };
    case GET_CLASS_SCHEDULES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case FILTER_CLASS_SCHEDULES:
      return { ...state, loading: true, filterError: null };
    case FILTER_CLASS_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,
        schedules: action.payload.schedules,
        meta: action.payload.meta
      };
    case FILTER_CLASS_SCHEDULES_ERROR:
      return { ...state, loading: false, filterError: action.payload };

    case GET_TIME_PAIRS:
      return { ...state, timePairsLoading: true, timePairsError: null };
    case GET_TIME_PAIRS_SUCCESS:
      return {
        ...state,
        timePairsLoading: false,
        timePairs: action.payload
      };
    case GET_TIME_PAIRS_ERROR:
      return { ...state, timePairsLoading: false, timePairsError: action.payload };

    case ADD_CLASS_SCHEDULES:
      return { ...state, loading: true, addError: null };
    case ADD_CLASS_SCHEDULES_SUCCESS:
      return { ...state, loading: false };
    case ADD_CLASS_SCHEDULES_ERROR:
      return { ...state, loading: false, addError: action.payload };

    case DELETE_CLASS_SCHEDULES:
      return { ...state, loading: true, deleteError: null };
    case DELETE_CLASS_SCHEDULES_SUCCESS:
      return { ...state, loading: false };
    case DELETE_CLASS_SCHEDULES_ERROR:
      return { ...state, loading: false, deleteError: action.payload };

    default:
      return state;
  }
};
