export const UserRole = {
  Admin: 'ADMIN',
  Teacher: 'TEACHER',
  Student: 'STUDENT',
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'vi';
export const localeOptions = [
  { id: 'vi', name: 'Vietnamese - LTR', direction: 'ltr' },
  { id: 'en', name: 'English - LTR', direction: 'ltr' }
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-9.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const adminRoot = '/app';
export const loginPage = '/user/login';

export const getUserPageByRole = (role) => {
  switch (role) {
    case UserRole.Admin:
      return '/app/menu/users';
    case UserRole.Teacher:
      return '/app/menu/class-schedules';
    case UserRole.Student:
      return '/app/menu/student-schedulings';
    default:
      return '/app/menu/users';
  }
};

// Default userPage for when no user is logged in or role is unknown
export const userPage = '/app/menu/users';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueyale';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
