import {
  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_ERROR,
  CREATE_CLASS,
  CREATE_CLASS_SUCCESS,
  CREATE_CLASS_ERROR,
  UPDATE_CLASS,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_ERROR,
  DELETE_CLASSES,
  DELETE_CLASSES_SUCCESS,
  DELETE_CLASSES_ERROR,
} from '../constants';

const INIT_STATE = {
  classes: [],
  meta: null,
  loading: false,
  error: null,
  createError: null,
  updateError: null,
  deleteError: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASSES:
      return { ...state, loading: true, error: null };
    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        loading: false,
        classes: action.payload.classes,
        meta: action.payload.meta
      };
    case GET_CLASSES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CREATE_CLASS:
      return { ...state, loading: true, createError: null };
    case CREATE_CLASS_SUCCESS:
      return { ...state, loading: false };
    case CREATE_CLASS_ERROR:
      return { ...state, loading: false, createError: action.payload };

    case UPDATE_CLASS:
      return { ...state, loading: true, updateError: null };
    case UPDATE_CLASS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_CLASS_ERROR:
      return { ...state, loading: false, updateError: action.payload };

    case DELETE_CLASSES:
      return { ...state, loading: true, deleteError: null };
    case DELETE_CLASSES_SUCCESS:
      return { ...state, loading: false };
    case DELETE_CLASSES_ERROR:
      return { ...state, loading: false, deleteError: action.payload };

    default:
      return state;
  }
};
