import {
  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_ERROR,
  CREATE_CLASS,
  CREATE_CLASS_SUCCESS,
  CREATE_CLASS_ERROR,
  UPDATE_CLASS,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_ERROR,
  DELETE_CLASSES,
  DELETE_CLASSES_SUCCESS,
  DELETE_CLASSES_ERROR,
} from '../constants';

export const getClasses = (params) => ({
  type: GET_CLASSES,
  payload: params
});

export const getClassesSuccess = (classes, meta) => ({
  type: GET_CLASSES_SUCCESS,
  payload: { classes, meta }
});

export const getClassesError = (error) => ({
  type: GET_CLASSES_ERROR,
  payload: error
});

export const createClass = (classData) => ({
  type: CREATE_CLASS,
  payload: classData
});

export const createClassSuccess = (classData) => ({
  type: CREATE_CLASS_SUCCESS,
  payload: classData
});

export const createClassError = (error) => ({
  type: CREATE_CLASS_ERROR,
  payload: error
});

export const updateClass = (classData) => ({
  type: UPDATE_CLASS,
  payload: classData
});

export const updateClassSuccess = (classData) => ({
  type: UPDATE_CLASS_SUCCESS,
  payload: classData
});

export const updateClassError = (error) => ({
  type: UPDATE_CLASS_ERROR,
  payload: error
});

export const deleteClasses = (classIds) => ({
  type: DELETE_CLASSES,
  payload: classIds
});

export const deleteClassesSuccess = () => ({
  type: DELETE_CLASSES_SUCCESS
});

export const deleteClassesError = (error) => ({
  type: DELETE_CLASSES_ERROR,
  payload: error
});
