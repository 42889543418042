import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';
import { GET_USERS, REGISTER_NEW_USER, GET_TEACHERS, UPDATE_USER, DELETE_USERS, GET_STUDENTS } from '../constants';
import { 
  getUsersSuccess, 
  getUsersError,
  registerNewUserSuccess,
  registerNewUserError,
  getTeachersSuccess,
  getTeachersError,
  updateUserSuccess,
  updateUserError,
  deleteUsersSuccess,
  deleteUsersError,
  getUsers,
  getStudentsSuccess,
  getStudentsError
} from './actions';

const getUsersRequest = async (params) => {
  const { order = 'ASC', page = 1, take = 10, searchKeyword = '', isNotInClass = '', role = '' } = params;
  try {
    const queryParams = new URLSearchParams({
      order,
      page: page.toString(),
      take: take.toString(),
      ...(searchKeyword && { searchKeyword }),
      ...(isNotInClass && { isNotInClass }),
      ...(role && { role })
    });
    
    const response = await api.get(`/users?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch users';
  }
};

function* getUsersItems({ payload }) {
  try {
    const response = yield call(getUsersRequest, payload);
    yield put(getUsersSuccess(response.data, response.meta));
  } catch (error) {
    yield put(getUsersError(error));
  }
}

const registerNewUserRequest = async (userData) => {
  const formData = new FormData();
  Object.keys(userData).forEach(key => {
    formData.append(key, userData[key]);
  });
  
  try {
    const response = await api.post('/auth/register', formData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to register user';
  }
};

function* registerNewUserItem({ payload }) {
  try {
    const response = yield call(registerNewUserRequest, payload);
    yield put(registerNewUserSuccess(response));
    // Refresh the users list with the current role
    yield put(getUsers({
      page: 1,
      take: 10,
      order: 'DESC',
      role: payload.role // Add role parameter from the submitted user data
    }));
  } catch (error) {
    console.log('error', error);
    let errorMessage = error;
    if (error === 'error.unique.email') {
      errorMessage = 'Email đã tồn tại';
    }
    yield put(registerNewUserError(errorMessage));
  }
}

const getTeachersRequest = async () => {
  try {
    const response = await api.get('/users?page=1&take=500000&role=TEACHER');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch teachers';
  }
};

function* getTeachersItems() {
  try {
    const response = yield call(getTeachersRequest);
    yield put(getTeachersSuccess(response));
  } catch (error) {
    yield put(getTeachersError(error));
  }
}

const updateUserRequest = async (userData) => {
  try {
    const response = await api.put(`/users/${userData.id}`, userData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update user';
  }
};

function* updateUserItem({ payload }) {
  try {
    const response = yield call(updateUserRequest, payload);
    yield put(updateUserSuccess(response));
    // Refresh the users list with the current role
    yield put(getUsers({
      page: 1,
      take: 10,
      order: 'DESC',
      role: payload.role // Add role parameter to maintain the filter
    }));
  } catch (error) {
    yield put(updateUserError(error));
  }
}

const deleteUsersRequest = async (payload) => {
  try {
    const { ids, role } = payload;
    const response = await api.post('/users/delete-bulk', ids);
    return { data: response.data, role };
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete users';
  }
};

function* deleteUsersItems({ payload }) {
  try {
    const result = yield call(deleteUsersRequest, payload);
    yield put(deleteUsersSuccess());
    
    // Use the role from the payload
    const role = result.role || '';
    
    // Refresh the users list with the current role
    yield put(getUsers({
      page: 1,
      take: 10,
      order: 'DESC',
      role
    }));
  } catch (error) {
    yield put(deleteUsersError(error));
  }
}

const getStudentsRequest = async () => {
  try {
    const response = await api.get('/users?page=1&take=500000&role=STUDENT');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch students';
  }
};

function* getStudentsItems() {
  try {
    const response = yield call(getStudentsRequest);
    yield put(getStudentsSuccess(response));
  } catch (error) {
    yield put(getStudentsError(error));
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsersItems);
}

export function* watchRegisterNewUser() {
  yield takeEvery(REGISTER_NEW_USER, registerNewUserItem);
}

export function* watchGetTeachers() {
  yield takeEvery(GET_TEACHERS, getTeachersItems);
}

export function* watchGetStudents() {
  yield takeEvery(GET_STUDENTS, getStudentsItems);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUserItem);
}

export function* watchDeleteUsers() {
  yield takeEvery(DELETE_USERS, deleteUsersItems);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchRegisterNewUser),
    fork(watchGetTeachers),
    fork(watchGetStudents),
    fork(watchUpdateUser),
    fork(watchDeleteUsers)
  ]);
} 