import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';
import {
  GET_CLASS_STUDENTS,
  ADD_CLASS_STUDENTS,
  DELETE_CLASS_STUDENTS
} from '../constants';
import {
  getClassStudentsSuccess,
  getClassStudentsError,
  addClassStudentsSuccess,
  addClassStudentsError,
  deleteClassStudentsSuccess,
  deleteClassStudentsError,
  getClassStudents
} from './actions';

const getClassStudentsRequest = async (classId, params) => {
  const { order = 'ASC', page = 1, take = 10, searchKeyword = '', startDate = '', endDate = '' } = params;
  try {
    const queryParams = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      order: order.toString(),
      ...(searchKeyword && { searchKeyword }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate })
    });
    
    const url = classId ? 
      `/class-students/class/${classId}/students?${queryParams.toString()}` :
      `/class-students/all?${queryParams.toString()}`;
      
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch class students';
  }
};

const addClassStudentsRequest = async (classId, studentIds) => {
  try {
    const response = await api.post(`/class-students/${classId}/students`, { 'students': studentIds });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to add students to class';
  }
};

const deleteClassStudentsRequest = async (payload) => {
  try {
    const response = await api.post(`/class-students/delete-bulk`, payload);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete class students';
  }
};

function* getClassStudentsItems({ payload }) {
  try {
    const { classId, params } = payload;
    const response = yield call(getClassStudentsRequest, classId, params);
    yield put(getClassStudentsSuccess(response.data, response.meta));
  } catch (error) {
    yield put(getClassStudentsError(error));
  }
}

function* addClassStudentsItems({ payload }) {
  try {
    const { classId, studentIds } = payload;
    yield call(addClassStudentsRequest, classId, studentIds);
    yield put(addClassStudentsSuccess());
    yield put(getClassStudents(classId, { page: 1, take: 10 }));
  } catch (error) {
    yield put(addClassStudentsError(error));
  }
}

function* deleteClassStudentsItems({ payload }) {
  try {
    const { classId } = payload;
    yield call(deleteClassStudentsRequest, payload);
    yield put(deleteClassStudentsSuccess());
    // Refresh the list after deletion with the current classId
    yield put(getClassStudents(classId, { page: 1, take: 10 }));
  } catch (error) {
    yield put(deleteClassStudentsError(error));
  }
}

export function* watchGetClassStudents() {
  yield takeEvery(GET_CLASS_STUDENTS, getClassStudentsItems);
}

export function* watchAddClassStudents() {
  yield takeEvery(ADD_CLASS_STUDENTS, addClassStudentsItems);
}

export function* watchDeleteClassStudents() {
  yield takeEvery(DELETE_CLASS_STUDENTS, deleteClassStudentsItems);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetClassStudents),
    fork(watchAddClassStudents),
    fork(watchDeleteClassStudents)
  ]);
}
