import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';
import {
  GET_CLASS_SCHEDULES,
  ADD_CLASS_SCHEDULES,
  DELETE_CLASS_SCHEDULES,
  FILTER_CLASS_SCHEDULES,
  GET_TIME_PAIRS
} from '../constants';
import {
  getClassSchedulesSuccess,
  getClassSchedulesError,
  addClassSchedulesSuccess,
  addClassSchedulesError,
  deleteClassSchedulesSuccess,
  deleteClassSchedulesError,
  getClassSchedules,
  filterClassSchedules,
  filterClassSchedulesSuccess,
  filterClassSchedulesError,
  getTimePairsSuccess,
  getTimePairsError
} from './actions';

const getClassSchedulesRequest = async (classId, params) => {
  const { order = 'ASC', page = 1, take = 10 } = params;
  try {
    const queryParams = new URLSearchParams({
      order: order.toString(),
      page: page.toString(),
      take: take.toString()
    });
    
    const response = await api.get(`/class-schedules/class/${classId}?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch class schedules';
  }
};

const addClassSchedulesRequest = async (schedules) => {
  try {
    const response = await api.post('/class-schedules/bulk', { schedules });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to add class schedules';
  }
};

const deleteClassSchedulesRequest = async (scheduleIds) => {
  try {
    const response = await api.post('/class-schedules/delete-bulk', scheduleIds);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete class schedules';
  }
};

const filterClassSchedulesRequest = async (filters, params) => {
  const { classId, startTime, endTime, startDate, endDate } = filters;
  const { order = 'ASC', page = 1, take = 10 } = params;
  
  try {
    const queryParams = new URLSearchParams();
    
    // Add pagination and ordering params
    queryParams.append('order', order.toString());
    queryParams.append('page', page.toString());
    queryParams.append('take', take.toString());
    
    // Add filter params if they exist
    if (classId) queryParams.append('classId', classId);
    if (startTime) queryParams.append('startTime', startTime);
    if (endTime) queryParams.append('endTime', endTime);
    
    // Add date range filters if they exist
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);
    
    const response = await api.get(`/class-schedules/filter?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to filter class schedules';
  }
};

const getTimePairsRequest = async () => {
  try {
    const response = await api.get('/class-schedules/time-pairs');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch time pairs';
  }
};

function* getClassSchedulesItems({ payload }) {
  try {
    const { classId, params } = payload;
    const response = yield call(getClassSchedulesRequest, classId, params);
    yield put(getClassSchedulesSuccess(response.data, response.meta));
  } catch (error) {
    yield put(getClassSchedulesError(error));
  }
}

function* addClassSchedulesItems({ payload }) {
  try {
    const { schedules, filterParams } = payload;
    yield call(addClassSchedulesRequest, schedules);
    yield put(addClassSchedulesSuccess());
    
    // Refresh the list after adding using filterParams
    yield put(filterClassSchedules(filterParams, { page: 1, take: 10 }));
  } catch (error) {
    yield put(addClassSchedulesError(error));
  }
}

function* deleteClassSchedulesItems({ payload }) {
  try {
    const { scheduleIds, classId, filterParams } = payload;
    yield call(deleteClassSchedulesRequest, scheduleIds);
    yield put(deleteClassSchedulesSuccess());
    
    // Refresh the list after deletion using filterParams if available
    if (filterParams) {
      yield put(filterClassSchedules(filterParams, { page: 1, take: 10 }));
    } else {
      // Fallback to old behavior
      yield put(getClassSchedules(classId, { page: 1, take: 10 }));
    }
  } catch (error) {
    yield put(deleteClassSchedulesError(error));
  }
}

function* filterClassSchedulesItems({ payload }) {
  try {
    const { filters, params } = payload;
    const response = yield call(filterClassSchedulesRequest, filters, params);
    yield put(filterClassSchedulesSuccess(response.data, response.meta));
  } catch (error) {
    yield put(filterClassSchedulesError(error));
  }
}

function* getTimePairsItems() {
  try {
    const response = yield call(getTimePairsRequest);
    yield put(getTimePairsSuccess(response));
  } catch (error) {
    yield put(getTimePairsError(error));
  }
}

export function* watchGetClassSchedules() {
  yield takeEvery(GET_CLASS_SCHEDULES, getClassSchedulesItems);
}

export function* watchAddClassSchedules() {
  yield takeEvery(ADD_CLASS_SCHEDULES, addClassSchedulesItems);
}

export function* watchDeleteClassSchedules() {
  yield takeEvery(DELETE_CLASS_SCHEDULES, deleteClassSchedulesItems);
}

export function* watchFilterClassSchedules() {
  yield takeEvery(FILTER_CLASS_SCHEDULES, filterClassSchedulesItems);
}

export function* watchGetTimePairs() {
  yield takeEvery(GET_TIME_PAIRS, getTimePairsItems);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetClassSchedules),
    fork(watchAddClassSchedules),
    fork(watchDeleteClassSchedules),
    fork(watchFilterClassSchedules),
    fork(watchGetTimePairs)
  ]);
}
