import {
  GET_CLASS_STUDENTS,
  GET_CLASS_STUDENTS_SUCCESS,
  GET_CLASS_STUDENTS_ERROR,
  ADD_CLASS_STUDENTS,
  ADD_CLASS_STUDENTS_SUCCESS,
  ADD_CLASS_STUDENTS_ERROR,
  DELETE_CLASS_STUDENTS,
  DELETE_CLASS_STUDENTS_SUCCESS,
  DELETE_CLASS_STUDENTS_ERROR,
} from '../constants';

const INIT_STATE = {
  students: [],
  meta: null,
  loading: false,
  error: null,
  addError: null,
  deleteError: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASS_STUDENTS:
      return { ...state, loading: true, error: null };
    case GET_CLASS_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload.students,
        meta: action.payload.meta
      };
    case GET_CLASS_STUDENTS_ERROR:
      return { ...state, loading: false, error: action.payload, students: [] };

    case ADD_CLASS_STUDENTS:
      return { ...state, loading: true, addError: null };
    case ADD_CLASS_STUDENTS_SUCCESS:
      return { ...state, loading: false };
    case ADD_CLASS_STUDENTS_ERROR:
      return { ...state, loading: false, addError: action.payload };

    case DELETE_CLASS_STUDENTS:
      return { ...state, loading: true, deleteError: null };
    case DELETE_CLASS_STUDENTS_SUCCESS:
      return { ...state, loading: false };
    case DELETE_CLASS_STUDENTS_ERROR:
      return { ...state, loading: false, deleteError: action.payload };

    default:
      return state;
  }
};
