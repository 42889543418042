import {
    SEND_CERTIFICATE,
    SEND_CERTIFICATE_SUCCESS,
    SEND_CERTIFICATE_ERROR,
  } from '../constants';
  
  export const sendCertificate = (studentName, email) => ({
    type: SEND_CERTIFICATE,
    payload: { studentName, email }
  });
  
  export const sendCertificateSuccess = () => ({
    type: SEND_CERTIFICATE_SUCCESS
  });
  
  export const sendCertificateError = (error) => ({
    type: SEND_CERTIFICATE_ERROR,
    payload: error
  });