import {
  GET_STUDENT_SCHEDULES,
  GET_STUDENT_SCHEDULES_SUCCESS,
  GET_STUDENT_SCHEDULES_ERROR,
  CREATE_STUDENT_SCHEDULE,
  CREATE_STUDENT_SCHEDULE_SUCCESS,
  CREATE_STUDENT_SCHEDULE_ERROR,
  DELETE_STUDENT_SCHEDULES,
  DELETE_STUDENT_SCHEDULES_SUCCESS,
  DELETE_STUDENT_SCHEDULES_ERROR,
  GET_STUDENT_SCHEDULES_BY_STUDENT,
  GET_STUDENT_SCHEDULES_BY_STUDENT_SUCCESS,
  GET_STUDENT_SCHEDULES_BY_STUDENT_ERROR,
  UPDATE_STUDENT_SCHEDULE,
  UPDATE_STUDENT_SCHEDULE_SUCCESS,
  UPDATE_STUDENT_SCHEDULE_ERROR,
  UPDATE_STUDENT_SCHEDULES_ATTENDANCE,
  UPDATE_STUDENT_SCHEDULES_ATTENDANCE_SUCCESS,
  UPDATE_STUDENT_SCHEDULES_ATTENDANCE_ERROR,
  GET_STUDENT_SCHEDULES_BY_DATE,
  GET_STUDENT_SCHEDULES_BY_DATE_SUCCESS,
  GET_STUDENT_SCHEDULES_BY_DATE_ERROR,
} from '../constants';

const INIT_STATE = {
  items: [],
  loading: false,
  error: '',
  meta: null,
  selectedItems: [],
  addError: null,
  deleteError: null,
  studentSchedules: [],
  studentSchedulesMeta: null,
  updateError: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STUDENT_SCHEDULES:
      return { ...state, loading: true, error: '' };

    case GET_STUDENT_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        meta: action.payload.meta,
      };

    case GET_STUDENT_SCHEDULES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CREATE_STUDENT_SCHEDULE:
      return { ...state, loading: true, error: '' };

    case CREATE_STUDENT_SCHEDULE_SUCCESS:
      return { ...state, loading: false };

    case CREATE_STUDENT_SCHEDULE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DELETE_STUDENT_SCHEDULES:
      return { ...state, loading: true, error: '' };

    case DELETE_STUDENT_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedItems: [],
      };

    case DELETE_STUDENT_SCHEDULES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_STUDENT_SCHEDULES_BY_STUDENT:
      return { ...state, loading: true, error: '' };

    case GET_STUDENT_SCHEDULES_BY_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        studentSchedules: action.payload.data,
        studentSchedulesMeta: action.payload.meta,
      };

    case GET_STUDENT_SCHEDULES_BY_STUDENT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_STUDENT_SCHEDULE:
      return { ...state, loading: true, updateError: null };

    case UPDATE_STUDENT_SCHEDULE_SUCCESS:
      return { ...state, loading: false };

    case UPDATE_STUDENT_SCHEDULE_ERROR:
      return { ...state, loading: false, updateError: action.payload };

    case UPDATE_STUDENT_SCHEDULES_ATTENDANCE:
      return { ...state, loading: true, error: null };

    case UPDATE_STUDENT_SCHEDULES_ATTENDANCE_SUCCESS:
      return { ...state, loading: false };

    case UPDATE_STUDENT_SCHEDULES_ATTENDANCE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_STUDENT_SCHEDULES_BY_DATE:
      return { ...state, loading: true, error: null };

    case GET_STUDENT_SCHEDULES_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        meta: action.payload.meta
      };

    case GET_STUDENT_SCHEDULES_BY_DATE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};