import {
  GET_STUDENT_SCHEDULES,
  GET_STUDENT_SCHEDULES_SUCCESS,
  GET_STUDENT_SCHEDULES_ERROR,
  CREATE_STUDENT_SCHEDULE,
  CREATE_STUDENT_SCHEDULE_SUCCESS,
  CREATE_STUDENT_SCHEDULE_ERROR,
  DELETE_STUDENT_SCHEDULES,
  DELETE_STUDENT_SCHEDULES_SUCCESS,
  DELETE_STUDENT_SCHEDULES_ERROR,
  GET_STUDENT_SCHEDULES_BY_STUDENT,
  GET_STUDENT_SCHEDULES_BY_STUDENT_SUCCESS,
  GET_STUDENT_SCHEDULES_BY_STUDENT_ERROR,
  UPDATE_STUDENT_SCHEDULE,
  UPDATE_STUDENT_SCHEDULE_SUCCESS,
  UPDATE_STUDENT_SCHEDULE_ERROR,
  UPDATE_STUDENT_SCHEDULES_ATTENDANCE,
  UPDATE_STUDENT_SCHEDULES_ATTENDANCE_SUCCESS,
  UPDATE_STUDENT_SCHEDULES_ATTENDANCE_ERROR,
  GET_STUDENT_SCHEDULES_BY_DATE,
  GET_STUDENT_SCHEDULES_BY_DATE_SUCCESS,
  GET_STUDENT_SCHEDULES_BY_DATE_ERROR,
} from '../constants';

export const getStudentSchedules = (classScheduleId, params) => ({
  type: GET_STUDENT_SCHEDULES,
  payload: { classScheduleId, params }
});

export const getStudentSchedulesSuccess = (data, meta) => ({
  type: GET_STUDENT_SCHEDULES_SUCCESS,
  payload: { data, meta }
});

export const getStudentSchedulesError = (error) => ({
  type: GET_STUDENT_SCHEDULES_ERROR,
  payload: error
});

export const createStudentSchedule = (studentId, classScheduleId) => ({
  type: CREATE_STUDENT_SCHEDULE,
  payload: { studentId, classScheduleId }
});

export const createStudentScheduleSuccess = () => ({
  type: CREATE_STUDENT_SCHEDULE_SUCCESS
});

export const createStudentScheduleError = (error) => ({
  type: CREATE_STUDENT_SCHEDULE_ERROR,
  payload: error
});

export const deleteStudentSchedules = (ids, classScheduleId) => ({
  type: DELETE_STUDENT_SCHEDULES,
  payload: { ids, classScheduleId }
});

export const deleteStudentSchedulesSuccess = () => ({
  type: DELETE_STUDENT_SCHEDULES_SUCCESS
});

export const deleteStudentSchedulesError = (error) => ({
  type: DELETE_STUDENT_SCHEDULES_ERROR,
  payload: error
});

export const getStudentSchedulesByStudent = (studentId, params) => ({
  type: GET_STUDENT_SCHEDULES_BY_STUDENT,
  payload: { studentId, params }
});

export const getStudentSchedulesByStudentSuccess = (data, meta) => ({
  type: GET_STUDENT_SCHEDULES_BY_STUDENT_SUCCESS,
  payload: { data, meta }
});

export const getStudentSchedulesByStudentError = (error) => ({
  type: GET_STUDENT_SCHEDULES_BY_STUDENT_ERROR,
  payload: error
});

export const updateStudentSchedule = (studentScheduleId, data) => ({
  type: UPDATE_STUDENT_SCHEDULE,
  payload: { studentScheduleId, data }
});

export const updateStudentScheduleSuccess = () => ({
  type: UPDATE_STUDENT_SCHEDULE_SUCCESS
});

export const updateStudentScheduleError = (error) => ({
  type: UPDATE_STUDENT_SCHEDULE_ERROR,
  payload: error
});

export const updateStudentSchedulesAttendance = (scheduleIds, attendance, classScheduleId) => ({
  type: UPDATE_STUDENT_SCHEDULES_ATTENDANCE,
  payload: { scheduleIds, attendance, classScheduleId }
});

export const updateStudentSchedulesAttendanceSuccess = () => ({
  type: UPDATE_STUDENT_SCHEDULES_ATTENDANCE_SUCCESS
});

export const updateStudentSchedulesAttendanceError = (error) => ({
  type: UPDATE_STUDENT_SCHEDULES_ATTENDANCE_ERROR,
  payload: error
});

export const getStudentSchedulesByDate = (startDate, endDate, teacherId) => ({
  type: GET_STUDENT_SCHEDULES_BY_DATE,
  payload: { startDate, endDate, teacherId }
});

export const getStudentSchedulesByDateSuccess = (data, meta) => ({
  type: GET_STUDENT_SCHEDULES_BY_DATE_SUCCESS,
  payload: { data, meta }
});

export const getStudentSchedulesByDateError = (error) => ({
  type: GET_STUDENT_SCHEDULES_BY_DATE_ERROR,
  payload: error
});