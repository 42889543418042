import {
    GET_CLASS_SCHEDULES,
    GET_CLASS_SCHEDULES_SUCCESS,
    GET_CLASS_SCHEDULES_ERROR,
    ADD_CLASS_SCHEDULES,
    ADD_CLASS_SCHEDULES_SUCCESS,
    ADD_CLASS_SCHEDULES_ERROR,
    DELETE_CLASS_SCHEDULES,
    DELETE_CLASS_SCHEDULES_SUCCESS,
    DELETE_CLASS_SCHEDULES_ERROR,
    FILTER_CLASS_SCHEDULES,
    FILTER_CLASS_SCHEDULES_SUCCESS,
    FILTER_CLASS_SCHEDULES_ERROR,
    GET_TIME_PAIRS,
    GET_TIME_PAIRS_SUCCESS,
    GET_TIME_PAIRS_ERROR,
  } from '../constants';
  
  export const getClassSchedules = (classId, params) => ({
    type: GET_CLASS_SCHEDULES,
    payload: { classId, params }
  });
  
  export const getClassSchedulesSuccess = (schedules, meta) => ({
    type: GET_CLASS_SCHEDULES_SUCCESS,
    payload: { schedules, meta }
  });
  
  export const getClassSchedulesError = (error) => ({
    type: GET_CLASS_SCHEDULES_ERROR,
    payload: error
  });
  
  export const addClassSchedules = (payload) => ({
    type: ADD_CLASS_SCHEDULES,
    payload
  });
  
  export const addClassSchedulesSuccess = () => ({
    type: ADD_CLASS_SCHEDULES_SUCCESS
  });
  
  export const addClassSchedulesError = (error) => ({
    type: ADD_CLASS_SCHEDULES_ERROR,
    payload: error
  });
  
  export const deleteClassSchedules = (scheduleIds) => ({
    type: DELETE_CLASS_SCHEDULES,
    payload: scheduleIds
  });
  
  export const deleteClassSchedulesSuccess = () => ({
    type: DELETE_CLASS_SCHEDULES_SUCCESS
  });
  
  export const deleteClassSchedulesError = (error) => ({
    type: DELETE_CLASS_SCHEDULES_ERROR,
    payload: error
  });

  export const filterClassSchedules = (filters, params) => ({
    type: FILTER_CLASS_SCHEDULES,
    payload: { filters, params }
  });

  export const filterClassSchedulesSuccess = (schedules, meta) => ({
    type: FILTER_CLASS_SCHEDULES_SUCCESS,
    payload: { schedules, meta }
  });

  export const filterClassSchedulesError = (error) => ({
    type: FILTER_CLASS_SCHEDULES_ERROR,
    payload: error
  });

  export const getTimePairs = () => ({
    type: GET_TIME_PAIRS
  });

  export const getTimePairsSuccess = (timePairs) => ({
    type: GET_TIME_PAIRS_SUCCESS,
    payload: timePairs
  });

  export const getTimePairsError = (error) => ({
    type: GET_TIME_PAIRS_ERROR,
    payload: error
  });