import axios from 'axios';

// const API_URL = 'http://localhost:3001';
const API_URL = 'https://swapi.kadivn.com';

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Login failed';
  }
};

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('auth_token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('auth_token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const token = localStorage.getItem('auth_token');
if (token) {
  setAuthToken(token);
} 

const api = axios.create({
  // baseURL: 'http://localhost:3001'
  baseURL: 'https://swapi.kadivn.com'
});

api.interceptors.request.use((config) => {
  const authToken = localStorage.getItem('auth_token');
  if (authToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${authToken}`
      }
    };
  }
  return config;
});

export default api; 