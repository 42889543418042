import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';
import {
  GET_STUDENT_SCHEDULES,
  CREATE_STUDENT_SCHEDULE,
  DELETE_STUDENT_SCHEDULES,
  GET_STUDENT_SCHEDULES_BY_STUDENT,
  UPDATE_STUDENT_SCHEDULE,
  UPDATE_STUDENT_SCHEDULES_ATTENDANCE,
  GET_STUDENT_SCHEDULES_BY_DATE
} from '../constants';
import {
  getStudentSchedulesSuccess,
  getStudentSchedulesError,
  createStudentScheduleSuccess,
  createStudentScheduleError,
  deleteStudentSchedulesSuccess,
  deleteStudentSchedulesError,
  getStudentSchedules,
  getStudentSchedulesByStudentSuccess,
  getStudentSchedulesByStudentError,
  updateStudentScheduleSuccess,
  updateStudentScheduleError,
  getStudentSchedulesByStudent,
  updateStudentSchedulesAttendanceSuccess,
  updateStudentSchedulesAttendanceError,
  getStudentSchedulesByDateSuccess,
  getStudentSchedulesByDateError,
} from './actions';

function* getStudentSchedulesItems({ payload }) {
  try {
    const { classScheduleId, params } = payload;
    const searchKeyword = params?.searchKeyword;
    const queryParams = new URLSearchParams({
      page: params.page.toString(),
      take: params.take.toString(),
      hasStudentFeedback: params.hasStudentFeedback,
      ...(searchKeyword && { searchKeyword }),
    });

    const response = yield call(api.get, 
      `/student-schedules/class-schedule/${classScheduleId}?${queryParams.toString()}`
    );
    yield put(getStudentSchedulesSuccess(response.data.data, response.data.meta));
  } catch (error) {
    yield put(getStudentSchedulesError(error.message));
  }
}

function* createStudentScheduleItem({ payload }) {
  try {
    const { studentId, classScheduleId } = payload;
    yield call(api.post, '/student-schedules', { studentId, classScheduleId });
    yield put(createStudentScheduleSuccess());
    // Refresh the list after creating
    yield put(getStudentSchedules(classScheduleId, { page: 1, take: 10 }));
  } catch (error) {
    yield put(createStudentScheduleError(error.message));
  }
}

function* deleteStudentSchedulesItems({ payload }) {
  try {
    console.log('payload', payload);
    yield call(api.post, '/student-schedules/delete-bulk', payload.ids);
    yield put(deleteStudentSchedulesSuccess());
    // Refresh the list after deletion
    yield put(getStudentSchedules(payload.classScheduleId, { page: 1, take: 10 }));
  } catch (error) {
    yield put(deleteStudentSchedulesError(error.message));
  }
}

function* getStudentSchedulesByStudentItems({ payload }) {
  try {
    const { studentId, params } = payload;
    const queryParams = new URLSearchParams({
      page: params.page.toString(),
      take: params.take.toString(),
    });

    const response = yield call(
      api.get,
      `/student-schedules/student/${studentId}?${queryParams.toString()}`
    );
    yield put(getStudentSchedulesByStudentSuccess(response.data.data, response.data.meta));
  } catch (error) {
    yield put(getStudentSchedulesByStudentError(error.message));
  }
}

function* updateStudentScheduleItem({ payload }) {
  try {
    const { studentScheduleId, data } = payload;
    yield call(api.put, `/student-schedules/${studentScheduleId}`, data);
    yield put(updateStudentScheduleSuccess());
    
    // Refresh the list after updating
    // Note: You'll need to store the current studentId and params somewhere 
    // to refresh the list properly
    const currentStudentId = data.studentId; // You need to ensure this is available
    yield put(getStudentSchedulesByStudent(currentStudentId, { page: 1, take: 10 }));
  } catch (error) {
    yield put(updateStudentScheduleError(error.message));
  }
}

function* updateStudentSchedulesAttendanceItems({ payload }) {
  try {
    const { scheduleIds, attendance, classScheduleId } = payload;
    yield call(api.post, '/student-schedules/update-attendance-bulk', {
      scheduleIds,
      attendance
    });
    yield put(updateStudentSchedulesAttendanceSuccess());
    // Refresh the list after updating
    yield put(getStudentSchedules(classScheduleId, { 
      page: 1, 
      take: 10,
      hasStudentFeedback: false 
    }));
  } catch (error) {
    yield put(updateStudentSchedulesAttendanceError(error.message));
  }
}

function* getStudentSchedulesByDateItems({ payload }) {
  try {
    const { startDate, endDate, teacherId } = payload;
    const queryParams = new URLSearchParams({
      startDate,
      endDate,
      teacherId
    });

    const response = yield call(
      api.get,
      `/student-schedules?${queryParams.toString()}`
    );

    console.log('response', response.data);
    yield put(getStudentSchedulesByDateSuccess(response.data, response.data.meta));
  } catch (error) {
    yield put(getStudentSchedulesByDateError(error.message));
  }
}

export function* watchGetList() {
  yield takeEvery(GET_STUDENT_SCHEDULES, getStudentSchedulesItems);
}

export function* watchCreateItem() {
  yield takeEvery(CREATE_STUDENT_SCHEDULE, createStudentScheduleItem);
}

export function* watchDeleteItems() {
  yield takeEvery(DELETE_STUDENT_SCHEDULES, deleteStudentSchedulesItems);
}

export function* watchGetStudentSchedulesByStudent() {
  yield takeEvery(GET_STUDENT_SCHEDULES_BY_STUDENT, getStudentSchedulesByStudentItems);
}

export function* watchUpdateStudentSchedule() {
  yield takeEvery(UPDATE_STUDENT_SCHEDULE, updateStudentScheduleItem);
}

export function* watchUpdateStudentSchedulesAttendance() {
  yield takeEvery(UPDATE_STUDENT_SCHEDULES_ATTENDANCE, updateStudentSchedulesAttendanceItems);
}

export function* watchGetStudentSchedulesByDate() {
  yield takeEvery(GET_STUDENT_SCHEDULES_BY_DATE, getStudentSchedulesByDateItems);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchCreateItem),
    fork(watchDeleteItems),
    fork(watchGetStudentSchedulesByStudent),
    fork(watchUpdateStudentSchedule),
    fork(watchUpdateStudentSchedulesAttendance),
    fork(watchGetStudentSchedulesByDate),
  ]);
}
