import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:3001'
  baseURL: 'https://swapi.kadivn.com'
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth_token');
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    };
  }
  return config;
});

export default api; 