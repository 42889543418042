import {
  GET_CLASS_STUDENTS,
  GET_CLASS_STUDENTS_SUCCESS,
  GET_CLASS_STUDENTS_ERROR,
  ADD_CLASS_STUDENTS,
  ADD_CLASS_STUDENTS_SUCCESS,
  ADD_CLASS_STUDENTS_ERROR,
  DELETE_CLASS_STUDENTS,
  DELETE_CLASS_STUDENTS_SUCCESS,
  DELETE_CLASS_STUDENTS_ERROR,
} from '../constants';

export const getClassStudents = (classId, params) => ({
  type: GET_CLASS_STUDENTS,
  payload: { classId, params }
});

export const getClassStudentsSuccess = (students, meta) => ({
  type: GET_CLASS_STUDENTS_SUCCESS,
  payload: { students, meta }
});

export const getClassStudentsError = (error) => ({
  type: GET_CLASS_STUDENTS_ERROR,
  payload: error
});

export const addClassStudents = (classId, studentIds) => ({
  type: ADD_CLASS_STUDENTS,
  payload: { classId, studentIds }
});

export const addClassStudentsSuccess = () => ({
  type: ADD_CLASS_STUDENTS_SUCCESS
});

export const addClassStudentsError = (error) => ({
  type: ADD_CLASS_STUDENTS_ERROR,
  payload: error
});

export const deleteClassStudents = (payload) => ({
  type: DELETE_CLASS_STUDENTS,
  payload
});

export const deleteClassStudentsSuccess = () => ({
  type: DELETE_CLASS_STUDENTS_SUCCESS
});

export const deleteClassStudentsError = (error) => ({
  type: DELETE_CLASS_STUDENTS_ERROR,
  payload: error
});
