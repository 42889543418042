import {
  SEND_CERTIFICATE,
  SEND_CERTIFICATE_SUCCESS,
  SEND_CERTIFICATE_ERROR,
} from '../constants';

const INIT_STATE = {
  loading: false,
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_CERTIFICATE:
      return { ...state, loading: true, error: null };
    
    case SEND_CERTIFICATE_SUCCESS:
      return { ...state, loading: false };
    
    case SEND_CERTIFICATE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
