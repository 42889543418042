import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';
import { SEND_CERTIFICATE } from '../constants';
import {
  sendCertificateSuccess,
  sendCertificateError
} from './actions';
import { NotificationManager } from 'components/common/react-notifications';

const sendCertificateRequest = async (studentName, email) => {
  try {
    const response = await api.post('/certificates/send', {
      studentName,
      email
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to send certificate';
  }
};

function* sendCertificateItem({ payload }) {
  try {
    const { studentName, email } = payload;
    yield call(sendCertificateRequest, studentName, email);
    yield put(sendCertificateSuccess());
    NotificationManager.success(
      'Certificate sent successfully',
      'Success',
      3000,
      null,
      null,
      ''
    );
  } catch (error) {
    yield put(sendCertificateError(error));
    NotificationManager.warning(
      error,
      'Send Certificate Error',
      3000,
      null,
      null,
      ''
    );
  }
}

export function* watchSendCertificate() {
  yield takeEvery(SEND_CERTIFICATE, sendCertificateItem);
}

export default function* rootSaga() {
  yield all([fork(watchSendCertificate)]);
}
