import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';
import { 
  GET_CLASSES, 
  CREATE_CLASS, 
  UPDATE_CLASS, 
  DELETE_CLASSES 
} from '../constants';
import {
  getClassesSuccess,
  getClassesError,
  createClassSuccess,
  createClassError,
  updateClassSuccess,
  updateClassError,
  deleteClassesSuccess,
  deleteClassesError,
  getClasses
} from './actions';

const getClassesRequest = async (params) => {
  const { order = 'ASC', page = 1, take = 10, searchKeyword = '' } = params;
  try {
    const queryParams = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      order: order.toString(),
      ...(searchKeyword && { searchKeyword })
    });
    
    const response = await api.get(`/classes?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch classes';
  }
};

const createClassRequest = async (classData) => {
  try {
    const response = await api.post('/classes', classData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to create class';
  }
};

const updateClassRequest = async (classData) => {
  try {
    const response = await api.put(`/classes/${classData.id}`, classData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update class';
  }
};

const deleteClassesRequest = async (classIds) => {
  try {
    const response = await api.post('/classes/delete-bulk', classIds);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete classes';
  }
};

function* getClassesItems({ payload }) {
  try {
    const response = yield call(getClassesRequest, payload);
    yield put(getClassesSuccess(response.data, response.meta));
  } catch (error) {
    yield put(getClassesError(error));
  }
}

function* createClassItem({ payload }) {
  try {
    const response = yield call(createClassRequest, payload);
    yield put(createClassSuccess(response));
    yield put(getClasses({ page: 1, take: 10 }));
  } catch (error) {
    yield put(createClassError(error));
  }
}

function* updateClassItem({ payload }) {
  try {
    const response = yield call(updateClassRequest, payload);
    yield put(updateClassSuccess(response));
    yield put(getClasses({ page: 1, take: 10 }));
  } catch (error) {
    yield put(updateClassError(error));
  }
}

function* deleteClassesItems({ payload }) {
  try {
    yield call(deleteClassesRequest, payload);
    yield put(deleteClassesSuccess());
    yield put(getClasses({ page: 1, take: 10 }));
  } catch (error) {
    yield put(deleteClassesError(error));
  }
}

export function* watchGetClasses() {
  yield takeEvery(GET_CLASSES, getClassesItems);
}

export function* watchCreateClass() {
  yield takeEvery(CREATE_CLASS, createClassItem);
}

export function* watchUpdateClass() {
  yield takeEvery(UPDATE_CLASS, updateClassItem);
}

export function* watchDeleteClasses() {
  yield takeEvery(DELETE_CLASSES, deleteClassesItems);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetClasses),
    fork(watchCreateClass),
    fork(watchUpdateClass),
    fork(watchDeleteClasses)
  ]);
}
