import { GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR, REGISTER_NEW_USER, REGISTER_NEW_USER_SUCCESS, REGISTER_NEW_USER_ERROR, GET_TEACHERS, GET_TEACHERS_SUCCESS, GET_TEACHERS_ERROR, UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR, DELETE_USERS, DELETE_USERS_SUCCESS, DELETE_USERS_ERROR, GET_STUDENTS, GET_STUDENTS_SUCCESS, GET_STUDENTS_ERROR } from '../constants';

const INIT_STATE = {
  users: [],
  teachers: [],
  students: [],
  meta: null,
  loading: false,
  error: null,
  registrationLoading: false,
  registrationError: null,
  teachersLoading: false,
  teachersError: null,
  studentsLoading: false,
  studentsError: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return { ...state, loading: true, error: null };
    
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        meta: action.payload.meta,
        error: null
      };
    
    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload
      };

    case REGISTER_NEW_USER:
      return { ...state, registrationLoading: true, registrationError: null };
    
    case REGISTER_NEW_USER_SUCCESS:
      return {
        ...state,
        registrationLoading: false,
        users: [action.payload, ...state.users],
        registrationError: null
      };
    
    case REGISTER_NEW_USER_ERROR:
      return {
        ...state,
        registrationLoading: false,
        registrationError: action.payload
      };

    case GET_TEACHERS:
      return { ...state, teachersLoading: true, teachersError: null };
    
    case GET_TEACHERS_SUCCESS:
      return {
        ...state,
        teachersLoading: false,
        teachers: action.payload.data,
        teachersError: null
      };
    
    case GET_TEACHERS_ERROR:
      return {
        ...state,
        teachersLoading: false,
        teachers: [],
        teachersError: action.payload
      };

    case GET_STUDENTS:
      return { ...state, studentsLoading: true, studentsError: null };
    
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        studentsLoading: false,
        students: action.payload.data,
        studentsError: null
      };
    
    case GET_STUDENTS_ERROR:
      return {
        ...state,
        studentsLoading: false,
        students: [],
        studentsError: action.payload
      };

    case UPDATE_USER:
      return { ...state, loading: true, error: null };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.map(user => 
          user.id === action.payload.id ? action.payload : user
        ),
        error: null
      };

    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case DELETE_USERS:
      return { ...state, loading: true, error: null };

    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case DELETE_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}; 