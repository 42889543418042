/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const API_LOGIN_USER = 'API_LOGIN_USER';
export const API_LOGIN_USER_SUCCESS = 'API_LOGIN_USER_SUCCESS';
export const API_LOGIN_USER_ERROR = 'API_LOGIN_USER_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

/* USERS */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const REGISTER_NEW_USER_SUCCESS = 'REGISTER_NEW_USER_SUCCESS';
export const REGISTER_NEW_USER_ERROR = 'REGISTER_NEW_USER_ERROR';
export const GET_TEACHERS = 'GET_TEACHERS';
export const GET_TEACHERS_SUCCESS = 'GET_TEACHERS_SUCCESS';
export const GET_TEACHERS_ERROR = 'GET_TEACHERS_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETE_USERS = 'DELETE_USERS';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_ERROR = 'DELETE_USERS_ERROR';
export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_ERROR = 'GET_STUDENTS_ERROR';

/* Class constants */
export const GET_CLASSES = 'GET_CLASSES';
export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS';
export const GET_CLASSES_ERROR = 'GET_CLASSES_ERROR';

export const CREATE_CLASS = 'CREATE_CLASS';
export const CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS';
export const CREATE_CLASS_ERROR = 'CREATE_CLASS_ERROR';

export const UPDATE_CLASS = 'UPDATE_CLASS';
export const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS';
export const UPDATE_CLASS_ERROR = 'UPDATE_CLASS_ERROR';

export const DELETE_CLASSES = 'DELETE_CLASSES';
export const DELETE_CLASSES_SUCCESS = 'DELETE_CLASSES_SUCCESS';
export const DELETE_CLASSES_ERROR = 'DELETE_CLASSES_ERROR';

/* Class Students */
export const GET_CLASS_STUDENTS = 'GET_CLASS_STUDENTS';
export const GET_CLASS_STUDENTS_SUCCESS = 'GET_CLASS_STUDENTS_SUCCESS';
export const GET_CLASS_STUDENTS_ERROR = 'GET_CLASS_STUDENTS_ERROR';

export const ADD_CLASS_STUDENTS = 'ADD_CLASS_STUDENTS';
export const ADD_CLASS_STUDENTS_SUCCESS = 'ADD_CLASS_STUDENTS_SUCCESS';
export const ADD_CLASS_STUDENTS_ERROR = 'ADD_CLASS_STUDENTS_ERROR';

export const DELETE_CLASS_STUDENTS = 'DELETE_CLASS_STUDENTS';
export const DELETE_CLASS_STUDENTS_SUCCESS = 'DELETE_CLASS_STUDENTS_SUCCESS';
export const DELETE_CLASS_STUDENTS_ERROR = 'DELETE_CLASS_STUDENTS_ERROR';

/* Class Schedules */
export const GET_CLASS_SCHEDULES = 'GET_CLASS_SCHEDULES';
export const GET_CLASS_SCHEDULES_SUCCESS = 'GET_CLASS_SCHEDULES_SUCCESS';
export const GET_CLASS_SCHEDULES_ERROR = 'GET_CLASS_SCHEDULES_ERROR';

export const FILTER_CLASS_SCHEDULES = 'FILTER_CLASS_SCHEDULES';
export const FILTER_CLASS_SCHEDULES_SUCCESS = 'FILTER_CLASS_SCHEDULES_SUCCESS';
export const FILTER_CLASS_SCHEDULES_ERROR = 'FILTER_CLASS_SCHEDULES_ERROR';

export const GET_TIME_PAIRS = 'GET_TIME_PAIRS';
export const GET_TIME_PAIRS_SUCCESS = 'GET_TIME_PAIRS_SUCCESS';
export const GET_TIME_PAIRS_ERROR = 'GET_TIME_PAIRS_ERROR';

export const ADD_CLASS_SCHEDULES = 'ADD_CLASS_SCHEDULES';
export const ADD_CLASS_SCHEDULES_SUCCESS = 'ADD_CLASS_SCHEDULES_SUCCESS';
export const ADD_CLASS_SCHEDULES_ERROR = 'ADD_CLASS_SCHEDULES_ERROR';

export const DELETE_CLASS_SCHEDULES = 'DELETE_CLASS_SCHEDULES';
export const DELETE_CLASS_SCHEDULES_SUCCESS = 'DELETE_CLASS_SCHEDULES_SUCCESS';
export const DELETE_CLASS_SCHEDULES_ERROR = 'DELETE_CLASS_SCHEDULES_ERROR';

/* Student Schedules Constants */
export const GET_STUDENT_SCHEDULES = 'GET_STUDENT_SCHEDULES';
export const GET_STUDENT_SCHEDULES_SUCCESS = 'GET_STUDENT_SCHEDULES_SUCCESS';
export const GET_STUDENT_SCHEDULES_ERROR = 'GET_STUDENT_SCHEDULES_ERROR';

export const CREATE_STUDENT_SCHEDULE = 'CREATE_STUDENT_SCHEDULE';
export const CREATE_STUDENT_SCHEDULE_SUCCESS = 'CREATE_STUDENT_SCHEDULE_SUCCESS';
export const CREATE_STUDENT_SCHEDULE_ERROR = 'CREATE_STUDENT_SCHEDULE_ERROR';

export const DELETE_STUDENT_SCHEDULES = 'DELETE_STUDENT_SCHEDULES';
export const DELETE_STUDENT_SCHEDULES_SUCCESS = 'DELETE_STUDENT_SCHEDULES_SUCCESS';
export const DELETE_STUDENT_SCHEDULES_ERROR = 'DELETE_STUDENT_SCHEDULES_ERROR';

/* Student Schedules By Student */ 
export const GET_STUDENT_SCHEDULES_BY_STUDENT = 'GET_STUDENT_SCHEDULES_BY_STUDENT';
export const GET_STUDENT_SCHEDULES_BY_STUDENT_SUCCESS = 'GET_STUDENT_SCHEDULES_BY_STUDENT_SUCCESS';
export const GET_STUDENT_SCHEDULES_BY_STUDENT_ERROR = 'GET_STUDENT_SCHEDULES_BY_STUDENT_ERROR';

export const UPDATE_STUDENT_SCHEDULE = 'UPDATE_STUDENT_SCHEDULE';
export const UPDATE_STUDENT_SCHEDULE_SUCCESS = 'UPDATE_STUDENT_SCHEDULE_SUCCESS';
export const UPDATE_STUDENT_SCHEDULE_ERROR = 'UPDATE_STUDENT_SCHEDULE_ERROR';

export const UPDATE_STUDENT_SCHEDULES_ATTENDANCE = 'UPDATE_STUDENT_SCHEDULES_ATTENDANCE';
export const UPDATE_STUDENT_SCHEDULES_ATTENDANCE_SUCCESS = 'UPDATE_STUDENT_SCHEDULES_ATTENDANCE_SUCCESS';
export const UPDATE_STUDENT_SCHEDULES_ATTENDANCE_ERROR = 'UPDATE_STUDENT_SCHEDULES_ATTENDANCE_ERROR';

export const GET_STUDENT_SCHEDULES_BY_DATE = 'GET_STUDENT_SCHEDULES_BY_DATE';
export const GET_STUDENT_SCHEDULES_BY_DATE_SUCCESS = 'GET_STUDENT_SCHEDULES_BY_DATE_SUCCESS';
export const GET_STUDENT_SCHEDULES_BY_DATE_ERROR = 'GET_STUDENT_SCHEDULES_BY_DATE_ERROR';

export const SEND_CERTIFICATE = 'SEND_CERTIFICATE';
export const SEND_CERTIFICATE_SUCCESS = 'SEND_CERTIFICATE_SUCCESS';
export const SEND_CERTIFICATE_ERROR = 'SEND_CERTIFICATE_ERROR';