import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import users from './users/reducer';
import classes from './classes/reducer';
import classStudents from './class-students/reducer';
import classSchedules from './class-schedules/reducer';
import studentSchedules from './student-schedules/reducer';
import certificates from './certificates/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  users,
  classes,
  classStudents,
  classSchedules,
  studentSchedules,
  certificates
});

export default reducers;
