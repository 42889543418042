import { GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR, REGISTER_NEW_USER, REGISTER_NEW_USER_SUCCESS, REGISTER_NEW_USER_ERROR, GET_TEACHERS, GET_TEACHERS_SUCCESS, GET_TEACHERS_ERROR, UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR, DELETE_USERS, DELETE_USERS_SUCCESS, DELETE_USERS_ERROR, GET_STUDENTS, GET_STUDENTS_SUCCESS, GET_STUDENTS_ERROR } from '../constants';

export const getUsers = (params) => ({
  type: GET_USERS,
  payload: params
});

export const getUsersSuccess = (users, meta) => ({
  type: GET_USERS_SUCCESS,
  payload: { users, meta }
});

export const getUsersError = (error) => ({
  type: GET_USERS_ERROR,
  payload: error
});

export const registerNewUser = (userData) => ({
  type: REGISTER_NEW_USER,
  payload: userData
});

export const registerNewUserSuccess = (user) => ({
  type: REGISTER_NEW_USER_SUCCESS,
  payload: user
});

export const registerNewUserError = (error) => ({
  type: REGISTER_NEW_USER_ERROR,
  payload: error
});

export const getTeachers = () => ({
  type: GET_TEACHERS
});

export const getTeachersSuccess = (teachers) => ({
  type: GET_TEACHERS_SUCCESS,
  payload: teachers
});

export const getTeachersError = (error) => ({
  type: GET_TEACHERS_ERROR,
  payload: error
});

export const updateUser = (userData) => ({
  type: UPDATE_USER,
  payload: userData
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user
});

export const updateUserError = (error) => ({
  type: UPDATE_USER_ERROR,
  payload: error
});

export const deleteUsers = (userIds) => ({
  type: DELETE_USERS,
  payload: userIds
});

export const deleteUsersSuccess = () => ({
  type: DELETE_USERS_SUCCESS
});

export const deleteUsersError = (error) => ({
  type: DELETE_USERS_ERROR,
  payload: error
});

export const getStudents = () => ({
  type: GET_STUDENTS
});

export const getStudentsSuccess = (students) => ({
  type: GET_STUDENTS_SUCCESS,
  payload: students
});

export const getStudentsError = (error) => ({
  type: GET_STUDENTS_ERROR,
  payload: error
}); 